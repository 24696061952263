import 'jquery-validation/dist/jquery.validate.min';
import 'jquery-validation/dist/additional-methods.min';
import 'select2';
import 'select2/dist/css/select2.css';

import Swal from 'sweetalert2';
import { module } from 'modujs';

export default class extends module {
  constructor(m) {
      super(m);
      this.events = {
        click: {
          toggleFav: 'toggleFav',
          copyLink: 'copyLink',
          mailLink: 'mailLink',
        },
      };

      this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
  }

  init() {
  }

  copyLink(e) {
    var $ = jQuery;
    var module = this;
    let target = $(e.currentTarget);
    let tooltip = $(e.currentTarget).children('.tooltip');


    function copyToClipboard(textToCopy) {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
      } else {
        // text area method
        let textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = 'fixed';
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand('copy') ? res() : rej();
          textArea.remove();
        });
      }
    }

    $.ajax({
      url: this.ajaxurl,
      type: 'POST',
      data: {
        action: 'get_fav_link',
      },
      beforeSend: function () {
        target.addClass('disabled');
      },
      complete: function () {
        target.removeClass('disabled');
      },
      success: function (data) {
        if (data && data.link) {
          copyToClipboard(data.link)
            .then(() => {
              $(tooltip).text('Lien copié dans votre presse-papier');
              setTimeout(() => {
                $(tooltip).text('Cliquez pour copier le lien');
              }, 5000);
            })
            .catch(() => {
              var textArea = document.createElement("textarea");
              textArea.value = data.link;
              textArea.style.position = "fixed"; //avoid scrolling to bottom
              document.body.appendChild(textArea);
              textArea.focus();
              textArea.select();

              try {
                var successful = document.execCommand("copy");
                var msg = successful ? "successful" : "unsuccessful";
                if (successful) {
                  $(tooltip).text("Lien copié dans votre presse-papier");
                }
                else {
                  if (navigator.userAgent.indexOf("Safari") != -1 && navigator.userAgent.indexOf("Chrome") == -1) {
                    $(tooltip).text("Appuyez sur les touches ⌘C pour copier le texte");
                  }
                  else {
                    $(tooltip).text(
                      "Appuyez sur les touches CTRL + C pour copier le texte"
                    );
                  }
                  setTimeout(() => {
                    $(tooltip).text("Cliquez pour copier le lien");
                  }, 5000);
                }
              } catch (err) {
                console.error(err);
              }

              // document.body.removeChild(textArea);
              return;
            });
        }
      },
    });
  }

  mailLink(e) {
    var $ = jQuery;
    var module = this;
    let target = $(e.currentTarget);
    let tooltip = $(e.currentTarget).children('.tooltip');

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });


    Swal.fire({
      title: `Partage de votre sélection`,
      html:
        '<form id="mailFavForm">' +
        '<input id="nom" name="nom" class="swal2-input" type="text" placeholder="Votre nom">' +
        '<input id="email" name="email" class="swal2-input" type="email" placeholder="Votre e-mail">' +
        '<input id="email_destinataire" name="email_destinataire" class="swal2-input" type="email" placeholder="E-mail du destinataire">' +
        '</form>',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Envoyer',
      customClass: {
        confirmButton: 'c-button -primary',
        denyButton: 'c-button -second',
        cancelButton: 'c-button -second',
      },
      validationMessage: 'Entrez une adresse e-mail valide',
      preConfirm: () => {
        let nom = document.getElementById('nom').value;
        let email = document.getElementById('email').value;
        let email_destinataire = document.getElementById('email_destinataire')
          .value;

        $('#mailFavForm').validate({
          ignore: [],
          lang: 'fr',
          errorElement: 'div',
          errorPlacement: function (error, element) {
            var placement = $(element).data('error');
            if (placement) {
              $(placement).append(error);
            } else {
              error.insertAfter(element);
            }
          },
          invalidHandler: function (form, validator) {
            var errors = validator.numberOfInvalids();
            if (errors) {
              validator.errorList[0].element.focus();
            }
          },
        });

        $('#nom').rules('add', {
          required: true,
          messages: {
            required: 'Nom requis',
          },
        });

        $('#email').rules('add', {
          required: true,
          email: true,
          messages: {
            required: 'E-mail requis',
            email: 'E-mail invalide',
          },
        });

        $('#email_destinataire').rules('add', {
          required: true,
          email: true,
          messages: {
            required: 'E-mail requis',
            email: 'E-mail invalide',
          },
        });

        if ($('#mailFavForm').valid()) {
          return {
            nom: nom,
            email: email,
            email_destinataire: email_destinataire,
          };
        }
      },
    }).then((infos) => {
      if (infos.isConfirmed) {
        $.ajax({
          type: 'POST',
          // eslint-disable-next-line no-undef
          url: module.ajaxurl,
          data: {
            action: 'send_selection',
            email: infos.value,
          },
          success: function (response_update) {
            if (response_update.success) {
              Toast.fire({
                icon: 'success',
                title: 'E-mail envoyé',
              });
            } else {
              Toast.fire({
                icon: 'error',
                title: `${response_update.message}`,
              });
            }
          },
        });
      }
    });
  }

  toggleFav(e) {
    var module = this;
    let id_formation = $(e.currentTarget).data("favoris-id");

    $.ajax({
      url: this.ajaxurl,
      type: 'POST',
      data: {
        action: 'fav_formation',
        id: id_formation,
      },
      beforeSend: function () {
        $('.fav[data-id="' + id_formation + '"]').addClass('disabled');
      },
      complete: function () {
        $('.fav[data-id="' + id_formation + '"]').removeClass('disabled');
      },
      success: function (data) {
        if (data) {

          if ($('.vue_favoris').length) {
            $('.results_wrapper .nb_results').text(data.has_fav);
          }

          if (data.status) {
            $('.fav[data-id="' + id_formation + '"]').addClass('active');
          } else {
            $('.fav[data-id="' + id_formation + '"]').removeClass('active');

            if ($('.vue_favoris').length) {
              $('.fav[data-id="' + id_formation + '"]')
                .closest('.line_formation')
                .fadeOut(500);
            }
          }


          $('.btn_favoris .nb').text(data.has_fav);
          if (data.has_fav) {
            $('.btn_favoris').fadeIn(500, function() {
              $('.btn_favoris').addClass('active');
            });
          } else {
            $('.btn_favoris').fadeOut(500, function() {
              $('.btn_favoris').removeClass('active');
            });

            if ($('.vue_favoris').length) {
              $('[data-favoris="copyLink"]').addClass('disabled');
              $('[data-favoris="copyLink"]').fadeOut(500);
              $('[data-favoris="mailLink"]').addClass("disabled");
              $('[data-favoris="mailLink"]').fadeOut(500);
            }
          }
        }
      },
    });
  }
}
