import 'jquery-validation/dist/jquery.validate.min';
import 'jquery-validation/dist/additional-methods.min';
import 'select2';
import 'select2/dist/css/select2.css';
import { module } from 'modujs';

export default class extends module {
  constructor(m) {
      super(m);
      this.events = {
        click: {
          selectFormation: "selectFormation"
        },
        keyup: {
          change: "change"
        }
      };

      this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
  }

  init() {
    var $ = jQuery;
    var module = this;
    let ajaxRequest = null;

    function delay(callback, ms) {
      var timer = 0;
      return function () {
        var context = this,
          args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          callback.apply(context, args);
        }, ms || 0);
      };
    }

    $(document).on('click', '[data-autosuggest="selectFormation"]', function (e) { 
      module.selectFormation(
        $(e.currentTarget).data('autosuggest-val'),
        $(e.currentTarget).data('autosuggest-titre')
      );
    });

    $(document).ready(function () {
      $('#Search').keyup(
        delay(function (e) {
          if (ajaxRequest != null) {
            ajaxRequest.abort();
          }

          ajaxRequest = $.ajax({
            url: module.ajaxurl,
            type: 'POST',
            data: {
              action: 'autosuggest_name',
              nom: $(this).val(),
              // retour: module.getData('retour'),
            },
            success: function (data) {
              if (data) {
                $('#suggest').addClass('active');
                $('#Search').addClass('hasSuggest');
                $('#suggest').show();
                $('#suggest').html(data);
              } else {
                $('#suggest').removeClass('active');
                $('#Search').removeClass('hasSuggest');
                $('#suggest').hide();
                $('#suggest').html();
              }
            },
          });
        }, 500)
      );
    });
  }

  selectFormation(val, titre) {
    var $ = jQuery;
    var module = this;

    window.location.href = '/recherche/?IDRef=' + val + '&Search=' + titre;

  }
}
