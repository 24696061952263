import 'jquery-inview/jquery.inview.min';

export default {
  init() {
	  // 

    /* Section Number InView */
    $('body').ready(function() {
      $('.b-chiffres').on('inview', function(event, isInView) {
        var scrollspy = $(this).attr('data-scrollspy');
        if (isInView && scrollspy == '0') {
          // element is now visible in the viewport
          $('.b-chiffres_number').each(function () {
            $(this).prop('Counter',0).animate({
              Counter: $(this).text(),
            }, {
              duration: 4000,
              easing: 'swing',
              step: function (now) {
                $(this).text(toArabic(Math.ceil(now)));
              },
            });
          });
          $(this).attr('data-scrollspy', '1');
        }
      });
    });

    function toArabic(x) {
      return x.toLocaleString('cs-CZ');
    }
    /* END Section Number InView */
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
