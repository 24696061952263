import 'jquery-validation/dist/jquery.validate.min';
import 'jquery-validation/dist/additional-methods.min';
import 'select2';
import 'select2/dist/css/select2.css';
import { module } from 'modujs';

export default class extends module {
  constructor(m) {
      super(m);
      this.events = {
        click: {
          submitAjax: 'submitAjax',
          submitFif: 'submitFif',
          submit: 'submit',
          next: 'goNext',
          prev: 'goPrev',
          uncheckableRadio: 'uncheckableRadio',
        },
      };

      this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
  }

  initSelect2() {
    $('.step_item.active select').each(function (index, element) {
      let id = 'select2_dropdown_' + index + '_' + $(element).attr('name');
      if (!$('#'+id).length) {
        $(element).after("<div id='" + id + "' class='select2_dropdown'></div>");
      }
      let dropdown = `#${id}`;
      let placeholder = null;
      if ($(element).attr('placeholder')) {
        placeholder = $(element).attr('placeholder');
      }
      
      $(element).select2({
        width: '100%',
        placeholder: placeholder,
        language: {
          noResults: function () {
            return 'Aucun résultat trouvé';
          },
        },
        dropdownParent: $(dropdown),
      });
      $(element).addClass('loaded')
    });
  }

  initValidation() {
    var $ = jQuery;
    var module = this;

    const form = module.getData('form');
    var $msg_error = 'Ce champ est obligatoire';
    var $msg_error_email =
      'Saisir une adresse email valide (ex: email@exemple.com)';
    var $msg_error_format = 'Format invalide';
    var $msg_error_group = 'Merci de remplir l\'un de ces champs';

    // Règle pour exiger qu'au moins un champ parmi plusieurs soit rempli
    $.validator.addMethod("requireAtLeastOne", function(value, element, options) {
      const selector = options.selector;
      const fields = $(selector);
      return fields.filter(function() {
        return module.isFieldFilled(this);
      }).length > 0;
    }, "Au moins l'un de ces champs doit être rempli.");

      
    let validator = $(form).validate({
      ignore: [],
      lang: 'fr',
      errorElement: 'div',
      errorPlacement: function (error, element) {
        var placement = $(element).data('error');
        if (placement) {
          $(placement).append(error);
        } else {
          error.insertAfter(element);
        }
      },
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass('select2-hidden-accessible')) {
          $('#select2-' + elem.attr('id') + '-container')
            .parent()
            .addClass(errorClass)
            .removeClass(validClass);
        } else {
          elem.addClass(errorClass).removeClass(validClass);
        }
      },
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass('select2-hidden-accessible')) {
          $('#select2-' + elem.attr('id') + '-container')
            .parent()
            .removeClass(errorClass)
            .addClass(validClass);
        } else {
          elem.removeClass(errorClass).addClass(validClass);
        }
      },
      invalidHandler: function (form, validator) {
        var errors = validator.numberOfInvalids();
        if (errors) {
          validator.errorList[0].element.focus();
        }
      },
    });

    const required = $('.step_item.active [data-validation]');

    required.each((index, element) => {
      var msg = $msg_error;
      var msg_email = $msg_error_email;
      var msg_format = $msg_error_format;

      if ($(element).data('error_msg')) {
        msg = $(element).data('error_msg');
        msg_email = $(element).data('error_msg');
        msg_format = $(element).data('error_msg');
      }
      if ($(element).data('error_msg_format')) {
        msg_format = $(element).data('error_msg_format');
      }
      if ($(element).data('error_msg_email')) {
        msg_email = $(element).data('error_msg_email');
      }

      if ($(element).data('required')) {
        $(element).rules('add', {
          required: true,
          messages: { required: msg },
        });
      }

      if ($(element).data('type') == 'radio') {
        $(element).rules('add', {
          required: true,
          messages: { required: msg },
        });
      }

      if ($(element).data('type') == 'email') {
        $(element).rules('add', {
          email: true,
          messages: { email: msg_email },
        });
      }

      if ($(element).data('type') == 'number') {
        $(element).rules('add', {
          digits: true,
          messages: { digits: msg.concat(' en chiffres') },
        });
      }

      if ($(element).data('type') == 'cp') {
        $(element).rules('add', {
          pattern: '^(([0-8][0-9])|(9[0-5])|(9[7-8]))[0-9]{3}$',
          messages: { pattern: msg_format },
        });
      }

      if ($(element).data('type') == 'securite_sociale') {
        $(element).rules('add', {
          pattern: '^(?<sexe>[1-478])\\s*?(?<annee>[0-9]{2})\\s*?(?<mois>0[1-9]|1[0-2]|[2-3][0-9]|4[0-2]|[5-9][0-9])\\s*?(?<departement>2[ABab]|[0-9]{2})\\s*?(?<commune>00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)\\s*?(?<ordre>00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})\\s*?(?<cle>0[1-9]|[1-8][0-9]|9[0-7])\\s*?$',
          messages: { pattern: msg_format },
        });
      }

      if ($(element).data('type') == 'siret') {
        $(element).rules('add', {
          pattern: '^\\d{14}$',
          messages: { pattern: msg_format },
        });
      }

      if ($(element).data('type') == 'telephone') {
        $(element).rules('add', {
          pattern:
            '^(?:(?:\\+|00)33[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$',
          messages: { pattern: msg_format },
        });
      }

      if ($(element).data('type') == 'annee') {
        $(element).rules('add', {
          pattern: '^(19|20|21)\\d{2}$',
          messages: { pattern: msg_format },
        });
      }

      if ($(element).data('mincheck')) {
        $(element).rules('add', {
          required: true,
          minlength: $(element).data('mincheck'),
          messages: {
            required: msg,
            minlength: msg,
          },
        });
      }

    });
    
    const requiredWith = this.$('requiredWith');
    requiredWith.forEach(function (element) {
      const $element = $(element);
      const $requiredLabel = $element.parent().parent().find('.required_label');
      $requiredLabel.hide();
      if (!$element.parents('.is-hidden').length) {
        $element.rules('add', {
          required: function(element) {
            const linkedInput = $(element).data('required_with');
            return module.isFieldFilled(linkedInput);
          },
          messages: { required: $msg_error }
        });

        // Ajouter un écouteur d'événements à l'élément lié
        const linkedSelector = $element.data('required-with');
        $(document).on('change', linkedSelector, function(event) {
          if ($(event.target).val()) {
            $requiredLabel.show();
          } else {
            $requiredLabel.hide();
          }
          // $element.valid(); // Déclencher la validation
        });
      }
    });

    const groupValidation = this.$('groupValidation');
    groupValidation.forEach(function (element) {
      const $element = $(element);
      const group = $element.data('group');
      $element.rules('add', {
        requireAtLeastOne: {
          selector: `[data-group="${group}"]`
        },
        messages: { requireAtLeastOne: $msg_error_group }
      });

      $(document).on('change keyup', $(`[data-group="${group}"]`), function() {
        $element.valid(); // Déclencher la validation
      });
    });

    
    return validator;
  }

  isFieldFilled(field) {
    const $field = $(field);
    if ($field.is(':checkbox, :radio')) {
      return $field.is(':checked');
    } else {
      return $field.val().trim() !== '';
    }
  }

  removeValidation() {
    var $ = jQuery;
    var module = this;
    
    const form = module.getData('form');

    const required = $('.step_item.active [data-validation]');

    required.each((index, element) => {
      $(element).rules('remove');
    });
    $(form).valid();
  }

  init() {
    var $ = jQuery;
    var module = this;

    setTimeout(() => {
      module.initSelect2();
      module.initValidation();
      this.call('update', false, 'Scroll');
    }, 100);
    
    $('select[data-required').on('select2:close', function () {
      $(this).valid();
    });

    $('#individuelle_select_formation').on('change', function () {
      let value = $(this).val();
      $('#individuelle_select_session').html('');
      $('#individuelle_select_session').trigger("change");
      $.ajax({
        url: module.ajaxurl,
        type: 'POST',
        data: {
          action: 'get_sessions',
          formation_id: value
        },
        dataType: "json",
        beforeSend: function () {
          $('#individuelle_select_session').prop('disabled', true);
        },
        success: function (response) {
          $('#individuelle_select_session').prop('disabled', false);
          if (response.success) {
            if (!response.data.length) {
              $('#individuelle_select_session').append("<option value=\"\">Aucune session disponible</option>")
            }
            else {
              $('#individuelle_select_session').append("<option value=\"\">Sélectionner une session</option>")
              response.data.forEach(element => {
                $('#individuelle_select_session').append("<option value=\"" + element.id + "\">" + element.titre + "</option>")
              });
              $('#individuelle_select_session').trigger("change");
            }
          }
        },
      });
    });

    if (this.getData('etape') && this.getData('etape').charAt(0) > 1) {
      let etape = this.getData('etape');
      if (etape.includes('C') || etape.includes('c')) {
        this.setType('collective');
      } else if (
        etape.charAt(0) === '4' &&
        (etape.includes('B') || etape.includes('b'))
      ) {
        this.goToStepForm(1, 4, 'commun');
      } else if (
        etape.charAt(0) === '2' &&
        (etape.includes('B') || etape.includes('b'))
      ) {
        this.goToStepForm(1, 2, 'commun');
      } else {
        this.setType('individuelle');
      }

      this.goToStepForm(1, etape.charAt(0), this.getType(), false);
    } else {
      $('div.step_item[data-index]').hide();
      $('div[data-index=1]').show().addClass('active');
    }
  }

  submitFif(e) {
    const target = e.currentTarget;
    var $ = jQuery; 
    var form = $(target).parents('form');
    const texte = $(target).html();

    if($(target).hasClass('disabled')) {
      return;
    }
    
    if (this.validateCurrentStep()) {
      let formData = new FormData(form[0]);

      // Gérer les formations séparément si nécessaire
      let formations = $('select[name="postionnement_formations"]').val();
      if (formations) {
        formData.delete('postionnement_formations'); // Supprimer l'ancienne entrée
        formations.forEach(formation => {
          formData.append('postionnement_formations[]', formation);
        });
      }

      // Convertir FormData en objet pour l'affichage dans la console (facultatif)
      let formDataObj = {};
      formData.forEach((value, key) => {
        if (formDataObj[key]) {
          if (!Array.isArray(formDataObj[key])) {
            formDataObj[key] = [formDataObj[key]];
          }
          formDataObj[key].push(value);
        } else {
          formDataObj[key] = value;
        }
      });

      // serialize = $.param(serialize);
      formData.append('action', 'save_fif');
  
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }

      // let data = {
      //   action: 'save_fif',
      //   data: formDataObj,
      // };

      let module = this;

      $.ajax({
        url: this.ajaxurl,
        type: 'POST',
        data: formData,
        processData: false,  // Dire à jQuery de ne pas traiter les données
        contentType: false,  // Dire à jQuery de ne pas définir le contentType
        beforeSend: function () {
          $(target).prop('disabled', true);
          $(target).addClass('disabled');
          $(target).text('Envoi en cours');
        },
        error: function () { 
          $(target).removeClass('disabled');
          $(target).prop('disabled', false);
          $(target).html(texte);
        },
        success: function (response) {
          if (response.redirectto) {
            window.location.href = response.redirectto;
          }
          if (response.success) {
            module.goToStepForm(1, 2, 'commun', false);
            form[0].reset();
            $(target).removeClass('disabled');
            $(target).prop('disabled', false);
            $(target).html(texte);
          } else {
            $(target).removeClass('disabled');
            $(target).prop('disabled', false);
            $(target).html(texte);
            form.find('.c-form_msg').addClass('u-margin-bottom-small');
            form
              .find('.c-form_msg')
              .html(
                '<p>Oops! Une erreur est survenue lors de l’envoi du message. Merci de réessayer.</p>'
              )
              .fadeIn(1000);
            form.find('.c-form_msg').removeClass('success');
            form.find('.c-form_msg').addClass('error');
          }
        },
      });
    } else {
      $(target).removeClass('disabled');
      $(target).prop('disabled', false);
      $(target).html(texte);
      form.find('.c-form_msg').addClass('u-margin-bottom-small');
      form
        .find('.c-form_msg')
        .html('<p>Oops! Veuillez remplir les champs obligatoires.</p>')
        .fadeIn(1000);
      form.find('.c-form_msg').removeClass('success');
      form.find('.c-form_msg').addClass('error');
    }
  }

  submitAjax(e) {
    const target = e.currentTarget;
    var $ = jQuery; 
    var form = $(target).parents('form');
    const texte = $(target).html();

    if($(target).hasClass('disabled')) {
      return;
    }
    
    if (this.validateCurrentStep()) {
      let serialize = form.serializeArray();
      let ref_additionnelles = serialize.filter((e) => {
        return e.name == 'collective_ref_formation';
      });

      serialize = serialize.filter((e) => {
        return e.name != 'collective_ref_formation';
      });

      if (ref_additionnelles.length) {
        let collective_ref_formation = [];
        $.each(ref_additionnelles, function (indexInArray, valueOfElement) {
          serialize.push({
            name: 'collective_ref_formation[]',
            value: valueOfElement.value,
          });
        });
      }

      serialize = $.param(serialize);

      let data = {
        action: 'save_devis',
        data: serialize,
      };

      let module = this;

      $.ajax({
        url: this.ajaxurl,
        type: 'POST',
        data: data,
        beforeSend: function () {
          $(target).prop('disabled', true);
          $(target).addClass('disabled');
          $(target).text('Envoi en cours');
        },
        error: function () { 
          $(target).removeClass('disabled');
          $(target).prop('disabled', false);
          $(target).html(texte);
        },
        success: function (response) {
          if (response.redirectto) {
            window.location.href = response.redirectto;
          }
          if (response.success) {
            module.goToStepForm(3, 4, 'commun');
            form[0].reset();
            $(target).removeClass('disabled');
            $(target).prop('disabled', false);
            $(target).html(texte);
          } else {
            $(target).removeClass('disabled');
            $(target).prop('disabled', false);
            $(target).html(texte);
            form.find('.c-form_msg').addClass('u-margin-bottom-small');
            form
              .find('.c-form_msg')
              .html(
                '<p>Oops! Une erreur est survenue lors de l’envoi du message. Merci de réessayer.</p>'
              )
              .fadeIn(1000);
            form.find('.c-form_msg').removeClass('success');
            form.find('.c-form_msg').addClass('error');
          }
        },
      });
    } else {
      $(target).removeClass('disabled');
      $(target).prop('disabled', false);
      $(target).html(texte);
      form.find('.c-form_msg').addClass('u-margin-bottom-small');
      form
        .find('.c-form_msg')
        .html('<p>Oops! Veuillez remplir les champs obligatoires.</p>')
        .fadeIn(1000);
      form.find('.c-form_msg').removeClass('success');
      form.find('.c-form_msg').addClass('error');
    }
  }

  submit(e) {
    const target = e.currentTarget;
    var form = jQuery(target).parents('form');

    if(form.valid()) {
      form.submit();
    } else {
      form.find('.c-form_msg').addClass('u-margin-top-small');
      form.find('.c-form_msg').html('<p>Oops! Veuillez remplir les champs.</p>').fadeIn(1000);
      form.find('.c-form_msg').removeClass('success');
      form.find('.c-form_msg').addClass('error');
    }
  }
  
  validateCurrentStep() {
    let isValid = true;
    if (this.getData('form')) {
      const form = this.getData('form');
      isValid = $(form).valid(); 
      return isValid;
    }
    else {
      $('.step_item.active [data-required]').each((index,elmt) => {
        if (
          (($(elmt)[0].type == 'text' || $(elmt)[0].type == 'email') &&
            !$(elmt).val()) ||
          ($(elmt)[0].type == 'checkbox' && !$(elmt).is(':checked'))
        ) {
          $(elmt).closest('div.input').addClass('has-error');
          if (isValid) {
            $(elmt).focus();
            this.call('scrollTo', elmt, 'Scroll');
          }
          isValid = false;
        }
      });
    }

    return isValid;
  }

  setType(type) {
    $('input[name=type_formation]').prop('checked', false);
    $('input[name=type_formation][value=' + type + ']').prop('checked', true);
  }

  setFormation(id) {
    $('input[name=type_formation]').prop('checked', false);
    $('input[name=type_formation][value=' + type + ']').prop('checked', true);
  }

  getType() {
    return $('form#devis')
      .serializeArray()
      .find((x) => x.name == 'type_formation').value;
  }

  goToStepForm(currentIndex, nextIndex, type = 'commun', addhistory = true) {
    let module = this;
    let resume = module.getResume(currentIndex, nextIndex, type);
    this.call('scrollTo', "top", 'Scroll');
    window.scrollTo(0, 0);

    $(`.c-heading div`).removeClass('active');
    $(`.c-heading div[data-type=${type}]`).addClass('active');
    
    if (currentIndex < nextIndex) {
      $(`div.step[data-index=${currentIndex}]`).addClass('done');
      $(`div.step_item[data-index=${currentIndex}]`).addClass('done');
      $(`div.step.active`).removeClass('done').addClass('done');
      $(`div.step_item.active`).removeClass('done').addClass('done');
    }
    else {
      $(`div.step.active`).removeClass('done');
      $(`div.step_item.active`).removeClass('done');
    }
    
    $(`div.step_item[data-index=${currentIndex}]`)
      .hide(300)
      .removeClass('active');
    $(`div.step[data-index=${currentIndex}]`).removeClass('active');

    $(`div.step_item[data-index=${nextIndex}][data-type=${type}]`)
      .show(300)
      .addClass('active');
    $(`div.step[data-index=${nextIndex}]`).addClass('active');

    
    if (addhistory) {
      let serialize = $(module.getData('form')).serializeArray();
      serialize = serialize.filter((e) => {
        return e.value != '';
      });

      let ref_additionnelles = serialize.filter((e) => {
        return e.name == 'collective_ref_formation';
      });

      serialize = serialize.filter((e) => {
        return e.name != 'collective_ref_formation';
      });

      if (ref_additionnelles.length) {
        let collective_ref_formation = [];
        $.each(ref_additionnelles, function (indexInArray, valueOfElement) {
          serialize.push({ name: 'collective_ref_formation[]', value: valueOfElement.value });
        });
      }

      serialize = $.param(serialize);

      if (type == "collective") {
        nextIndex = `${nextIndex}C`;
        currentIndex = `${currentIndex}C`;
      }
      serialize = `Etape=${currentIndex}&${serialize}`;
      window.mload.goTo(`${window.location.pathname}?${serialize}`,"",true);
      
      window.mload.goTo(`${window.location.pathname}?Etape=${nextIndex}`, '', true);
    }

    setTimeout(() => {
      module.initSelect2();
      module.initValidation();
      this.call('update', false, 'Scroll');
    }, 100);
  }

  goNext() {
    if (this.validateCurrentStep()) {
      let type = this.getType();
      let currentIndex = $('.step_item.active').data('index');
      let nextIndex = currentIndex + 1;
  
      if (nextIndex <= 1) {
        type = 'commun';
      }
      this.goToStepForm(currentIndex, nextIndex, type);
    }
  }

  goPrev() {
    this.removeValidation();
    let currentIndex = $('.step_item.active').data('index');
    let nextIndex = currentIndex - 1;

    let type = $('form#devis')
      .serializeArray()
      .find((x) => x.name == 'type_formation').value;

    if (nextIndex <= 1) {
      type = 'commun';
    }

    this.goToStepForm(currentIndex, nextIndex, type);
  }

  uncheckableRadio(e) {
    const target = e.currentTarget;
    if ($(target).is(':checked') && !$(target).hasClass('checked')) {
      let parent = $(target).closest('.input');
      parent.find('input.checked').removeClass('checked');
      $(target).addClass('checked');
    }
    else if ($(target).is(':checked') && $(target).hasClass('checked')) {
      $(target).prop('checked', false);
      $(target).removeClass('checked');
    }
  }

  getResume(currentIndex, nextIndex, type) {
    let $ = jQuery;
    let module = this;
    let form = module.getData('form');
    
    if (nextIndex > 1) {
      $.ajax({
        url: module.ajaxurl,
        type: 'POST',
        data: {
          action: 'get_resume_devis',
          data: $(form).serialize(),
          nextIndex: nextIndex
        },
        beforeSend: function () {
          $('.recap').html("");
        },
        error: function () {
        },
        success: function (response) {
          if (response.success) {
            $('.recap').html(response.data.html);
          }
        },
      });
    }
    else {
      $('.recap').html("");
    }
  }
}
