/**
 * External Dependencies
 */
import 'jquery';

import './locomotive/app';

import Router from './utils/Router';
import common from './routes/common';
import home from './routes/home';
// import recherche from './routes/recherche';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // recherche,
});

$(() => {
  routes.loadEvents()
});
