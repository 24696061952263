import 'select2';
import 'select2/dist/css/select2.css';
import { module } from 'modujs';

export default class extends module {
  constructor(m) {
    super(m);
    this.events = {
      click: {
        submit: "submit",
        removeFiltre: "removeFiltre",
      },
    };

    this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    this.formulaire = this.getData('formulaire');
    this.currentAjax = false;
  }

  init() {
    var $ = jQuery;
    let module = this;
    var form = $(module.formulaire);
    var $msg_error = 'Ce champ est obligatoire';
    var $msg_error_email = 'Saisir une adresse email valide (ex: email@exemple.com)';

    function formatState (opt) {
      if (!opt.id) {
        return opt.text;
      }

      var count = $(opt.element).attr('data-count');

      if (!count) {
        count = 0;
      }
      var $opt = $('<span class="result">' + opt.text + ' <span class="count bg-primary">' + count + '</span></span>');
      return $opt;
    };

    $('select').each(function (index, element) {
      let id = "select2_dropdown_" + index + "_" + $(element).attr('name');
      $(element).after("<div id='" + id + "' class='select2_dropdown'></div>");
      $(element).select2({
        width: '100%',
        language: {
          noResults: function () {
            return 'Aucun résultat trouvé';
          },
        },
        dropdownParent: $("#" + id),
        templateResult: formatState,
        // templateSelection: formatState
      });

      $(element).on('select2:select', function (e) {
        module.submitAjax();
      });
    });
  }

  removeFiltre(e) {
    let $ = jQuery;
    let module = this;
    let name = $(e.currentTarget).data('filtres-name');
    if ($('select[name="'+ name + '"]').length) {
      $('select[name="'+ name + '"]').val(null);
      $('select[name="'+ name + '"]').trigger('change');
    }
    else if ($('input[name="' + name + '"]').length) {
      $('input[name="' + name + '"]').val(null);
      $('input[name="' + name + '"]').trigger('change');
    }

    if (name == "Favoris" && $(".vue_favoris").length) {
      $(".vue_favoris").removeClass("vue_favoris");
    }

    $(e.currentTarget).closest('.filtre_actif').fadeOut(300);
    module.submitAjax();
  }

  submit(e) {
    var $ = jQuery;
    let module = this
    var form = $(module.formulaire);
    form.submit();
  }

  submitAjax() {
    let $ = jQuery;
    let module = this
    let form = $(module.formulaire);
    let filtres_actifs = form.serializeArray();

    if (this.currentAjax) {
      this.currentAjax.abort();
    }

    this.currentAjax = $.ajax({
      url: module.ajaxurl,
      type: 'POST',
      data: {
        action: "get_formations_filtres",
        data: filtres_actifs
      },
      beforeSend: function () {
        $('.section_resultats').addClass('loading');
        $('.section_filtres').addClass('loading');
      },
      error: function () {

      },
      success: function (response) {
        if (response.success) {
          const url = new URL(window.location);
          $.each(filtres_actifs, function (indexInArray, element) {
            url.searchParams.set(element.name, element.value);
          });
          // window.history.pushState({}, '', url);


          $('.section_resultats').html(response.data.resultats);
          $('.section_filtres_actifs').html(response.data.filtres_actifs);
          $('.section_filtres').html(response.data.filtres);
          module.init();
        }
      },
      complete: function () {
        $('.section_resultats').removeClass('loading');
        $('.section_filtres').removeClass('loading');
        // module.call('update', false, 'app');
      }
    });
  }
}
