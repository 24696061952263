import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            //open: 'togglePopup',
            //close: 'togglePopup',
            open: 'open',
            close: 'close',
            pagination: 'pagination',
          }
        }
    }

    init() {

    }

    /*togglePopup(e) {
      const target = e.currentTarget;
      const section = this.$('popup');
      console.log(section);
      if (section[0].classList.contains('is-open')) {
        section[0].classList.remove('is-open');
      } else {
        section[0].classList.add('is-open');
      }
    }*/

    open(e) {
      const target = e.currentTarget;
      const div = this.$('popup-wrap');
      const html = $(div).html();
      $('#c-popup').find('.c-popup_wrap').html(html);
      $('#c-popup').addClass('is-open');
      $('body').addClass('has_popup');
    }

    close() {
      $('#c-popup').removeClass('is-open');
      $('body').removeClass('has_popup');
    }

    pagination(e) {
      const target = e.currentTarget;
      const index = target.getAttribute('data-index');
      const newtarget = document.getElementById(index);
      this.close();
      $(newtarget).trigger('click');
    }
}
