import { module } from 'modujs';

export default class extends module {
  constructor(m) {
    super(m);

    this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
  }

  init() {
    var $ = jQuery;

    var module = this;
    var NinjaFormsAsyncForm = function(formID, targetContainer) {
      this.formID = formID;
      this.targetContainer = targetContainer;

      this.formHTML;
      this.formTemplates;
      this.formScripts;

      this.fetch = function(callback) {
          jQuery.post("/", { action: 'init_ninjaforms', async_form: true, form_id: this.formID }, this.fetchHandler.bind(this)).then(callback);
      };

      this.fetchHandler = function(response) {
          response = JSON.parse( response );

          window.nfFrontEnd = window.nfFrontEnd || response.nfFrontEnd;
          window.nfi18n = window.nfi18n || response.nfi18n || {};

          this.formHTML = response.form;
          this.formTemplates = response.templates;
          this.formScripts = response.scripts;
      };

      this.load = function() {
          this.loadFormHTML(this.formHTML, this.targetContainer);
          this.loadTemplates(this.formTemplates);
          this.loadScripts(this.formScripts);
      };

      this.loadFormHTML = function(form, targetContainer) {
          jQuery(targetContainer).append( form );
      };

      this.loadTemplates = function(templates) {
          document.body.innerHTML += templates;
      };

      this.loadScripts = function(scripts) {
          jQuery.each( scripts, function( nfScript ){
              var script = document.createElement('script');
              eval( scripts[nfScript].extra.data );
              window.nfFrontEnd = window.nfFrontEnd || nfFrontEnd;
              script.setAttribute('src',scripts[nfScript].src);
              var appendChild = document.head.appendChild(script);
          });
      };

      this.remove = function() {
          jQuery(this.targetContainer).empty();
      };
    };


    $(function() {
      var url = window.location.href;
      if ($(".nf-form-cont").length && !url.includes("form=1")) {
        if (url.indexOf("?") > -1) {
          url += "&form=1";
        } else {
          url += "?form=1";
        }
        history.replaceState({}, null, url);
        window.mload.goTo(`${url}`, "", false, false);
        // window.location.href = url;
      } else {
        url = new URL(url);
        let params = new URLSearchParams(url.search);
        params.delete("form");

        history.replaceState({}, null, url);
      }
    });



    // var form_id = module.getData('id');
    // var asyncForm = new NinjaFormsAsyncForm(form_id, '.ninja-forms-dynamic');

    // asyncForm.fetch(function () {
    //   asyncForm.load();
    // });

    // var $ = jQuery;

    // if ($('.nf-form-cont').length) {
    //   //let ids = [];
    //   //$('.nf-form-cont').each(function() {
    //   //ids.push($(this).attr('id'));
    //   //let $this = $(this);
    //   //   setTimeout(() => {
    //   $.ajax({
    //     url: this.ajaxurl,
    //     type: 'POST',
    //     data: {
    //       action: 'init_ninjaforms',
    //       form_id: form_id
    //     },
    //     success: function (response) {
    //       $('.nf-form-cont').append(response.form);
    //       // console.log(response.templates);
    //       $('body').append(response.templates);
    //       //   document.body.innerHTML += response.templates;

    //       $.each(response.scripts, function (nfScript, value) {
    //         var script = document.createElement('script');
    //         eval(response.scripts[nfScript].extra.data);
    //         window.nfFrontEnd = window.nfFrontEnd || nfFrontEnd;
    //         script.setAttribute('src', response.scripts[nfScript].src);
    //         document.head.appendChild(script);
    //       });
    //     },
    //   });
    //   //});
    //   // }, 10000);
    //   this.call('update', false, 'Scroll');
    // }
  }
}
